import { useStoreUsers } from "@coworker/app/src/hooks/useStoreContacts";
import { Trans } from "@coworker/locales";
import React from "react";
import SSRIcon from "@ingka/ssr-icon";
import peopleIcon from "@ingka/ssr-icon/paths/people";
import styles from "./task-card.module.css";

export const Creator = ({ creatorId }: { creatorId: string }) => {
  const users = useStoreUsers();
  const creatorName = React.useMemo(() => {
    const user = users.find(
      (user: any) => user.fixa_uid === creatorId || user.uid === creatorId
    );
    return user ? (
      `${user.given_name} ${user.surname}`
    ) : (
      <Trans>unknownUserString</Trans>
    );
  }, [users, creatorId]);

  return (
    <div className={styles["creator-wrapper"]}>
      <SSRIcon
        className={styles["assignee-icon"] as string}
        paths={peopleIcon}
      />
      <div className={styles["creator"]}>{creatorName}</div>
    </div>
  );
};
