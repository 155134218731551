import React from "react";
import styles from "./task-card.module.css";
import SSRIcon from "@ingka/ssr-icon";
import crossIcon from "@ingka/ssr-icon/paths/cross-circle";
import { Trans, useTranslation } from "@coworker/locales";
import { useStoreTeams } from "@coworker/app/src/core/hooks/useStoreTeams";
import { getClosedTaskReason } from "../../helpers/getClosedTaskReason";
import { useLocation } from "react-router-dom";
import taskStateOptions from "@coworker/functions/src/enums/taskState.json";

interface ClosedByProps {
  assignedTeamId: string;
  state: string;
  closeType: string;
}

export const ClosedBy = ({
  assignedTeamId,
  state,
  closeType,
}: ClosedByProps) => {
  const teams = useStoreTeams();
  const location = useLocation();
  const { t } = useTranslation();
  const teamName =
    teams.find((team) => team.id === assignedTeamId)?.name ??
    t("unknownTeamString");

  const isTaskClosed = state === taskStateOptions.CLOSED;

  return (
    <>
      {isTaskClosed && !location.pathname.includes("related") && (
        <div className={styles["closed-wrapper"]}>
          <div>
            <Trans>closedByUppercaseString</Trans>: {teamName}
          </div>
          <div className={styles["closed-reason"]}>
            <SSRIcon
              className={styles["closed-icon"] as string}
              paths={crossIcon}
            />
            <div>
              <Trans>{getClosedTaskReason(closeType)}</Trans>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
